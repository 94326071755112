export default {
  home: {
    conn: '首頁',
    about:'關於UFC319',
    aboutinfo:`UFC319 是由海外投研團隊 Block BOX 實驗室和神秘代碼神孵化的 DEFI3.0 協議，
    由來自矽谷和新加坡的白帽子組成，是 BNB 鏈技術中最具創新性的協議生態！獨創的全端模型、多維協議、時空證明、純自治DAO 架構，是一支天才團隊孵化三年的成果，
    為了將WEB3.0 和DEFI 發揮到極致，團隊的野心是構建一個可持續增值收益的生態，打破傳統的一切束縛，一場偉大的革命即將到來！`,
    about1:'歡迎加入UFC319自治社群！',
    about2:'UFC319生態系',
    about3:'BNB鏈最創新的燃燒獎勵UFC319交易協議生態！ 100%真正無中心！公平、公開！沒有煎餅鍋！不用擔心鍋子被偷！杜絕機器人剪輯！合約自動永久有效！自由買賣！安全穩定！無需授權，無需網站系統！一個玩家自治的 DAO 生態系統，從不害怕關閉網路！沒有資料庫、沒有網路、沒有紅線。這是一個原始的DEFI模型。 ',
    about4:'',
    about5:'五個主要好處正在​​點燃加密貨幣世界 ',
    about6:'1。積極燃燒以賺取 BNB。',
    about7:'2。燃燒體重紅利。',
    about8:'3。持有代幣的股息。',
    about9:'4。共享節點（賺取BNB交易費用+燃燒本幣獎勵）',
    about10:'5。自治 DAO（BNB 交易費用）。',
    about11:'经典只能追赶，不能超越',
    about12:'1。多種協議的掌握者。 ',
    about13:'2。 BNB鏈首創的天才製作。 ',
    about14:'3。五維綜合收益管道將擁有超級永動機。',
    about15:'4。 50個主要社區的集體賦能共識。',
    about16:'DAO是治理文明的搖籃。',
    about17:'早期參賽玩家：',
    about18:'1。可以獲得自治DAO配額（100個席位），2個BNB可以獲得10萬本幣和5維管道收益。',
    about19:'2。可以複製 10 個號碼的佈局以最大化價值。',
    about20:'3。 1- 3個BNB投資創造超級共識，實現10000X。制定小結果和大結果的計劃。',
  },
  menuList: ['首頁', '搶佔燃燒'],
  
  burn: {
    ybp: '燃燒數據',
    gz: 'UFC319生態合約规则',
    rs: '燃燒',
    copy: '複製邀請链接',
    copydo: '複製',
    rsc: '當前可搶佔燃燒BNB總價值',
    maxrs: '當前可搶佔燃燒UFC319總量',
    address: '地址',
    lq:'領取',
    daofenhong:'我的DAO分紅',
    ranshaofenhong:'我的燃燒分紅',
    myUFC319:'我的UFC319余额',

    p1: '合約綁定地址生態關係的方法：',
    p2: '轉賬任意數量UFC319 至對方地址即可完成契約永久綁定。温馨提醒：必須是首次收到 UFC319的地址，否則为無效绑定。',
    p3: 'UFC319買賣交易方法 ',
    p4: 'BUY--買入UFC319：',
    p5: '轉賬BNB至UFC319合約地址 ',
    p6: '0x9738c604Edc9FfB345069Dab291B0591C22d64d1 ',
    p7: '即可按照當前幣價獲得相應數量的UFC319。',
    p8: 'SELL--賣出UFC319：',
    p9:'轉賬UFC319至UFC319合約地址',
    p10:'0x9738c604Edc9FfB345069Dab291B0591C22d64d1',
    p11:'即可按照當前幣價獲得相應價值的BNB。',
    p12:'特別提醒：敬請使用正確資產轉賬！',
    p13:'主動參與燃燒UFC319 的方法：',
    p14:'燃燒額度：全網可燃燒的BNB價值額度來自2%的交易所手續費、跟單賣出兌換的BNB及社區生態建設空投。用戶可通過DAPP網站查看當前燃燒額度參與燃燒，或者通過添加燃燒合約至觀察錢包，即時查看燃燒合約當前的BNB燃燒價值轉賬UFC319至燃燒合約參與燃燒。',
    p15:'參與燃燒：',
    p16:'轉賬UFC319至燃燒合約地址',
    p17:'0x37e3d3e67E1e333E84E5a78Dee02Ac9410C08485',
    p18:'即時獲得相應價值的BNB（回本）及相應數量的分紅權重。持有權重永久可享受交易手續費分潤（加權分紅。單次搶購燃燒額度不可超過當前持有量的50% 同一地址可重複參與，無數次限制。每個地址享受的分紅權重上限數量為2BNB權重。',
    p19:'燃燒獎勵：玩家燃燒的UFC319，70%即時燃燒至黑洞地址，30%觸發燃燒區塊獎勵分配。',
    p20:'特別備註：持幣數量少於5000枚UFC319的地址不可享受燃燒區塊獎勵。',
    p21:'底池自動燃燒：',
    p22:'底池代幣中嵌入每小時0.2%的銷毀，每天總計燃燒4.8%銷毀功能嵌入代幣中，將永遠自動銷毀。底池每天自動燃燒銷毀UFC319--底池UFC319不斷燃燒通縮，UFC319價格每天自動上漲。',
    p23:'跟單賣出合約機制--增加燃燒獎勵,加速通縮UFC319',
    p24:'跟單賣出合約工作邏輯：每當有地址買入UFC319的時候，會觸發跟賣合約自動賣出該筆買單量20%的UFC319兌換得到BNB 35%增加燃燒額度，10%燃燒權重分紅，5%自治DAO，50%暴力持幣分紅(加權分配給持5000枚代幣以上的用戶地址)。',

  }
}
