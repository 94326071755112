export default {
  home: {
    conn: 'Home',
    about:'About UFC319',
    aboutinfo:`UFC319 is a DEFI3.0 protocol incubated by the overseas investment research team Block BOX Laboratory and the mysterious code god. 
    It is composed of white hats from Silicon Valley and Singapore. It is the most innovative protocol ecosystem in BNB chain technology! 
    The original full-stack model, multi-dimensional protocol, space-time proof, 
    and pure autonomous DAO architecture are the result of three years of incubation by a talented team. 
    In order to maximize WEB3.0 and DEFI, 
    the team’s ambition is to build a sustainable value-added revenue ecosystem and break the All the shackles of tradition, a great revolution is coming!`,
    about1:'Welcome to join the UFC319 autonomous community!',
    about2:'UFC319 Ecosystem',
    about3:'BNB chain\'s most innovative burning reward UFC319 trading protocol ecology! 100% truly centerless! Fair and open! No pancake pot! No worries about pot being stolen! Put an end to robot clips! The contract runs automatically forever! Buy and sell freely! safe and stable! No authorization required, no website system! A player-autonomous DAO ecosystem that is never afraid of shutting down the Internet! There is no database, no network, and no red lines. It is an original DEFI model.',
    about4:'',
    about5:'Five major benefits are igniting the crypto world. ',
    about6:'1. Actively burning to earn BNB.',
    about7:'2. Burning weight dividends.',
    about8:'3. Dividends from holding coins.',
    about9:'4. Sharing nodes (earning BNB transaction fees + burning local currency rewards)',
    about10:'5. Autonomous DAO (BNB transaction fees).',
    about11:'Classics can only be caught up, not surpassed.',
    about12:'1. The master of multiple protocols. ',
    about13:'2. The genius production pioneered by BNB chain. ',
    about14:'3. The five-dimensional integrated revenue channel will have a super perpetual motion machine.',
    about15:'4. The collective empowerment consensus of 50 major communities.',
    about16:'DAO is the cradle of governance civilization.',
    about17:'Early participating players: ',
    about18:'1. Can obtain autonomous DAO quota (100 seats),2 BNB can obtain 100,000 local currency and 5-dimensional pipeline income.',
    about19:'2. Can copy the layout of 10 numbers to maximize value.',
    about20:'3. 1- The investment of 3 BNBs creates a super consensus and realizes the 10000X. plan with small and big results.',
},
menuList: ['Home', 'Burn'],

burn: {
ybp: 'Burning Data',
gz: 'UFC319 Rules',
rs: 'Burn',
copy: 'Copy the invitation link',
copydo: 'Copy',
rsc: 'The current total value of BNB that can be grabbed by burning',
maxrs: 'The current total amount of UFC319 that can be grabbed by burning',
address: 'Address',
lq: 'Withdraw',
daofenhong: 'DAO dividend',
ranshaofenhong: 'Burn dividend',
myUFC319: 'My UFC319',

p1: 'Method of contract binding address ecological relationship:',
 p2: 'Transfer any amount of UFC319 to the other party\'s address to complete the permanent binding of the contract. Warm reminder: This must be the first time you receive UFC319’s address, otherwise the binding will be invalid. ',
 p3: 'UFC319 buying and selling method',
 p4: 'BUY--Buy UFC319:',
 p5: 'Transfer BNB to UFC319 contract address',
 p6: '0x9738c604Edc9FfB345069Dab291B0591C22d64d1 ',
 p7: 'You can obtain the corresponding amount of UFC319 according to the current currency price. ',
 p8: 'SELL--Sell UFC319:',
 p9:'Transfer UFC319 to UFC319 contract address',
 p10:'0x9738c604Edc9FfB345069Dab291B0591C22d64d1',
 p11: 'You can obtain BNB of corresponding value according to the current currency price. ',
 p12: 'Special reminder: Please use the correct asset transfer! ',
 p13:'How to actively participate in burning UFC319:',
 p14: 'Burning quota: The BNB value quota that can be burned in the entire network comes from 2% exchange fees, BNB exchanged for documentary sales and community ecological construction airdrops. Users can view the current burning amount through the DAPP website to participate in burning, or add the burning contract to the observation wallet to instantly view the current BNB burning value of the burning contract and transfer UFC319 to the burning contract to participate in burning. ',
 p15:'Participate in burning:',
 p16: 'Transfer UFC319 to the burning contract address',
 p17:'0x37e3d3e67E1e333E84E5a78Dee02Ac9410C08485',
 p18:' Instantly obtain the corresponding value of BNB (recovery of capital) and the corresponding amount of dividend weight. Holding weights can permanently enjoy transaction fee dividends (weighted dividends. The burning amount of a single snap purchase cannot exceed 50% of the current holdings. The same address can participate repeatedly, with unlimited restrictions. The upper limit of the dividend weights enjoyed by each address is 2BNB Weights.',
 p19: 'Burning reward: 70% of the UFC319 burned by the player is immediately burned to the black hole address, and 30% triggers the burning block reward distribution. ',
 p20: 'Special note: Addresses holding less than 5,000 UFC319 coins cannot enjoy the burning block reward. ',
 p21:'The pot automatically burns:',
 p22: '0.2% burn per hour is embedded in the pot tokens, and a total of 4.8% is burned per day. The burn function is embedded in the tokens and will be automatically destroyed forever. The pot automatically burns and destroys UFC319 every day - the pot UFC319 continues to burn and deflate, and the price of UFC319 automatically increases every day. ',
 p23: 'Following selling contract mechanism - increasing burning rewards and accelerating deflation UFC319',
 p24: 'The working logic of the follow-up selling contract: Whenever an address buys UFC319, the follow-up selling contract will be triggered to automatically sell 20% of the purchase amount of UFC319 in exchange for BNB, which will increase the burning limit by 35% and the burning weight by 10%. Dividends, 5% autonomous DAO, 50% violent currency holding dividends (weighted distribution to user addresses holding more than 5,000 tokens). ',
  }
}
