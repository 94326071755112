import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    globalAddress:'0x******',
    shareAddress:'0xd3d17C7e3f92b650D2e51477b9cf2d99488e97ae'
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
