<!--
 * @Author: gaoyingying
 * @Date: 2023-04-06 13:24:23
 * @LastEditors: heyu
 * @LastEditTime: 2024-03-22 16:27:50
 * @FilePath: \3.22\src\App.vue
-->
<template>
  <div id="app">
    <a-config-provider :locale="zhCN">
    <router-view></router-view>
    </a-config-provider>
  </div>
</template>

<script>
import { defineComponent, ref, nextTick, provide, onMounted } from "vue";
import zhCN from "ant-design-vue/es/locale/zh_CN.js";
export default defineComponent({
  setup() {
    return {
      zhCN,
    };
  },
});
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  width: 100%;
  height: 100%;
  font-size: 0.18rem;
  background: #1b1b1b !important;
  color: #ffffff !important;
}
</style>
